<script setup>
// TODO: tady se duplikuje obsah v a / component. Uvnitr je jeste slot...

const props = defineProps({
  link: {
    type: [Object, String],
    default: null,
  },
  external: {
    type: [Object, String],
    default: null,
  },
  imageSrc: {
    type: String,
    default: null,
  },
  imageAlt: {
    type: String,
    default: undefined,
  },
  imageRatio: {
    type: String,
    default: undefined,
  },
  mobileImageRatio: {
    type: String,
    default: undefined,
  },
  /**
   * Heading text
   */
  heading: {
    type: String,
    default: null,
  },
  /**
   * Heading size (1 through 6)
   */
  headingLevel: {
    type: Number,
    default: 2,
  },
  subheading: {
    type: String,
    default: null,
  },
  /**
   * Vertical centering of text
   */
  vcenter: {
    type: Boolean,
    default: false,
  },
});

const type = computed(() => (props.link ? 'nuxt-link' : 'div'));
const subheadingLevel = computed(() => Math.min(6, props.headingLevel + 2));
const vAlign = computed(() => (props.vcenter ? 'center' : 'start'));
</script>

<template>
  <a v-if="external" :href="external" target="_blank" :class="{ 'card--link': link }" class="card">
    <div class="b-flex">
      <div v-if="imageSrc" sm="4" class="card__image b-flex-child">
        <RatioImage :src="useGetImageUrl(imageSrc, 600, 600)" :alt="imageAlt" :ratio="imageRatio" :mobile-ratio="mobileImageRatio" />
      </div>
      <div class="card__main" :class="{ 'mt-4 mt-sm-0': imageSrc }">
        <div v-if="heading" class="card__heading">
          <h2 :class="[`heading--${headingLevel}`]" class="heading">
            <div :class="{ heading__link: link }">{{ heading }}</div>
          </h2>
        </div>
        <div v-if="subheading" class="card__subheading mt-2">
          <h2 :class="[`heading--${subheadingLevel}`]" class="heading heading--thin">
            {{ subheading }}
          </h2>
        </div>
        <div :class="{ 'mt-3': heading }" class="card__text" v-if="$slots.default">
          <slot />
        </div>
      </div>
    </div>
    <div v-if="link" class="card__arrow">
      <i class="icon-arrow-right" />
    </div>
  </a>

  <nuxt-link v-else :to="link" :class="{ 'card--link': link }" class="card">
    <div class="b-flex">
      <div v-if="imageSrc" sm="4" class="card__image b-flex-child">
        <RatioImage :src="useGetImageUrl(imageSrc, 600, 600)" :alt="imageAlt" :ratio="imageRatio" :mobile-ratio="mobileImageRatio" />
      </div>
      <div class="card__main b-flex-child" :class="{ 'mt-4 mt-sm-0': imageSrc }">
        <div v-if="heading" class="card__heading">
          <h2 :class="[`heading--${headingLevel}`]" class="heading">
            <div :class="{ heading__link: link }" v-html="heading" />
          </h2>
        </div>

        <div v-if="subheading" class="card__subheading mt-2">
          <h2 :class="[`heading--${subheadingLevel}`]" class="heading heading--thin" v-html="subheading" />
        </div>
        <div :class="{ 'mt-3': heading }" class="card__text" v-if="$slots.default">
          <slot />
        </div>
      </div>
    </div>
    <div v-if="link" class="card__arrow">
      <i class="icon-arrow-right" />
    </div>
  </nuxt-link>
</template>

<style lang="scss" scoped>
.card {
  $s: &;

  display: block;
  position: relative;

  &--link {
    @include mq($lg) {
      padding-right: 35px;
    }
  }

  &__arrow {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: transform 0.2s;

    @include mq($lg) {
      display: block;
    }
  }

  &--link:hover {
    .heading__link {
      background-size: 100% 2px;
    }
  }
}

.card__image {
  min-width: 30%;
  max-width: 30%;
  @media (max-width: 576px) {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}
.b-flex {
  align-items: center;
}

.b-flex-child {
  &.card__main {
    min-width: 220px;
  }
}
</style>
